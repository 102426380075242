<template>
  <div>
    <div class="w-full pair bg-blue-200 dark:bg-blue-700 text-black dark:text-blue-50 clip-diagonal p-2 ">
      <slot />
    </div>
    <div class="w-full pair bg-blue-100 dark:bg-blue-950 text-blue-900 dark:text-blue-100 clip-diagonal-inverted p-2">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style>

.pair {
  position: absolute;
  left: 0px;
  top: 0px;
  min-height: 100%;
  padding-bottom: 32px;

}
.clip-diagonal {
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}

.clip-diagonal-inverted {
  clip-path: polygon(0 100%, 100% 0, 100% 100%);
}
</style>